// Contact form send
document.getElementById("contactForm").addEventListener("submit", function (e) {
    e.preventDefault();
    document.getElementById("contactFormBtn").disabled = true;
    let url = this.dataset.url;

    let formData = {};
    let data = new FormData(this);
    for (let [key, value] of data.entries()) {
        formData[key.toString()] = value;
    }
    fetch(url, {
        method: 'POST',
        credentials: "same-origin",
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.getElementsByName('csrf-token')[0].getAttribute('content')
        },
        body: JSON.stringify(formData),
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.status === 1) {
                this.reset();
                showAlertbox('success', data.msg);
            } else {
                let obj = data.msg;
                let message = '';
                Object.keys(obj).map(function(key) {
                    message += obj[key] + '\n';
                });
                showAlertbox('error', message);
            }
            document.getElementById("contactFormBtn").disabled = false;
        });
});

// // Price offer form send
// document.addEventListener('submit', function (e) {
//     if (!e.target.matches('#priceOfferForm')) return;
//     e.preventDefault();
//
//     document.getElementById("priceOfferFormBtn").disabled = true;
//     let url = e.target.getAttribute('data-url');
//     let form = document.getElementById(e.target.getAttribute('id'));
//
//     let formData = {};
//     let data = new FormData(form);
//     for (let [key, value] of data.entries()) {
//         formData[key.toString()] = value;
//     }
//     fetch(url, {
//         method: 'POST',
//         credentials: "same-origin",
//         headers: {
//             'Content-Type': 'application/json',
//             'X-CSRF-TOKEN': document.getElementsByName('csrf-token')[0].getAttribute('content')
//         },
//         body: JSON.stringify(formData),
//     })
//         .then((response) => response.json())
//         .then((data) => {
//             console.log(data.msg);
//             console.log(data.data);
//             if (data.status === 1) {
//                 form.reset();
//                 document.getElementById('js-productsHolder').innerHTML = data.html;
//                 showAlertbox('success', data.msg);
//             } else {
//                 let obj = data.msg;
//                 let message = '';
//                 Object.keys(obj).map(function(key) {
//                     message += obj[key] + '\n';
//                 });
//                 showAlertbox('error', message);
//             }
//             document.getElementById("priceOfferFormBtn").disabled = false;
//         });
// });



